import React, { useEffect } from 'react';

import promotion_config from '../../../../promotion_config';
import FastPlayEntryForm from './entry_form_fastplay';
import Error from '../error';
import BackButton from '../../widgets/back';
import PageLoading from '../../widgets/page_loading';
import Scanner from '../scanner';

import fastPlayTicketImg from '../../../global/cms/fastplay_ticket.png';
import { getHTML, getText } from '../../../utils/cms_copy_provider';
import { getUrl } from '../../../utils/header_url_provider';
import { isApp } from '../../../utils';

export const EntryInner = ({ entry, drawTicket, fastPlayTicket, scratchTicket, game, actions }) => {
    useEffect(() => {
        actions.revealTokenActions.getRevealToken();
    }, []);

    return (
        <div className='entry-div'>
            <div className='row'>
                <div className='col-md-10 offset-md-1 entry-container'>
                    <div className='entry-panel'>
                        <div className='fast-play-ticket'>
                            <div className='row'>
                                <div className='col'>
                                    <BackButton />
                                    <div className='description'>
                                        <h2>{getText('enter_fastplay_title')}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-7'>
                                    {isApp() ? (
                                        <Scanner />
                                    ) : (
                                        <>
                                            <div>{getHTML('enter_now_app_description')}</div>
                                            <div className='app-download-btn'>
                                                <a
                                                    href={
                                                        getUrl('Download on the App Store') ||
                                                        'https://itunes.apple.com/us/app/pa-lottery-official-app/id910842501?mt=8'
                                                    }
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    <img alt='Download on the App Store' role='presentation' src='/assets/images/Apple_App_Store.png' />
                                                </a>
                                                &nbsp;&nbsp;&nbsp;
                                                <a
                                                    href={getUrl('Get It On Google Play') || 'https://play.google.com/store/apps/details?id=com.sgi.palottery'}
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    <img alt='Get It On Google Play' role='presentation' src='/assets/images/Get_it_on_Google_play.png' />
                                                </a>
                                            </div>
                                            <div>{getHTML('enter_fastplay_description')}</div>
                                        </>
                                    )}
                                    <div id='fastplay-entry-alert'>
                                        <Error ticket={fastPlayTicket} actions={actions} />
                                        <PageLoading loadedAction='enterFastPlayTicket' hasIcon='true' />
                                    </div>
                                    <FastPlayEntryForm
                                        promotion_id={promotion_config.promotion_id}
                                        promotion={promotion_config.promotion}
                                        appUrl={promotion_config.appUrl}
                                        ticket={fastPlayTicket}
                                        entry={entry}
                                        game={game}
                                        actions={actions}
                                        createfunc={actions.fastPlayTicketActions.enterFastPlayTicket}
                                    />
                                </div>
                                <div className='col-md-5'>
                                    <img className='entry-ticket-img' src={fastPlayTicketImg} alt='Fast Play Ticket' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntryInner;
