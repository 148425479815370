import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../components/layouts/Layout';
import SEO from '../components/seo';
import EntryInner from '../components/enter/fastplay/entry_inner_fastplay';
import UserLockout from './../components/widgets/user_lockout';
import { getHTML, getText } from '../utils/cms_copy_provider';

export const Entry = ({
    entry,
    drawTicket,
    fastPlayTicket,
    scratchTicket,
    user,
    notification,
    game,
    config,
    actions,
}) => {
    const { gameOver } = drawTicket;

    return (
        <Layout
            user={user}
            config={config}
            notification={notification}
            actions={actions}
        >
            <SEO title="Enter Now" />
            <UserLockout>
                <div className="enter primary-page-content">
                    <EntryText
                        user={user}
                        drawTicket={drawTicket}
                        fastPlayTicket={fastPlayTicket}
                        scratchTicket={scratchTicket}
                        entry={entry}
                        game={game}
                        actions={actions}
                        gameOver={gameOver}
                    />
                </div>
            </UserLockout>
        </Layout>
    );
};

const EntryText = ({
    entry,
    user,
    drawTicket,
    fastPlayTicket,
    scratchTicket,
    game,
    actions,
    gameOver,
}) => {
    if (gameOver) {
        return (
            <div className="entry-over">
                <h2 className="text-center">
                    {getText('enter_msg_promotionended_title')}
                </h2>
                <h3 className="text-center uppercase">
                    {getHTML('enter_msg_promotionended_body')}
                </h3>
            </div>
        );
    } else {
        return (
            <EntryInner
                entry={entry}
                fastPlayTicket={fastPlayTicket}
                user={user}
                game={game}
                actions={actions}
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
